'use client'
import { useRouter } from 'next/navigation'
import { useState } from 'react'

export function SignupHeader() {
  const router = useRouter()
  const [ username, setUsername ] = useState( `` )

  const handleSubmit = ( event: React.FormEvent ) => {
    event.preventDefault()
    if ( username ) router.push( `/sign-up?username=${username}` )
  }

  const handleUsernameChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    setUsername( ( event.target as HTMLInputElement ).value )
  }

  return (
    <div className="p-4">
      <h1 className="text-4xl font-bold">{`Your one link for everything.`}</h1>
      <h2 className="m-6 text-xl text-gray-500">{`Share your links, social profiles, contact info and more on one page`}</h2>
      <form className="flex items-center" onSubmit={handleSubmit}>
        <span className="bg-white py-4 pl-4">{`tappedin.me/`}</span>
        <input
          className="p-4"
          type="text"
          placeholder="username"
          value={username}
          onChange={handleUsernameChange}
        />
        <button type="submit" className="bg-blue-500 p-4 px-6 text-white">{`Sign up`}</button>
      </form>
    </div>
  )
}

export default SignupHeader